.landing-container {
  text-align: center;
  margin-top: 20px;
  font-family: Arial, sans-serif;
  color: rgba(185, 240, 240, 0.75);
}

.flexy{
  display: flex;
  width: 100%;
  justify-content: space-evenly;
}

.content {
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
}

.title {
  font-size: 32px;
  margin: 20px 0;
  text-align: center;
}

.features h2 {
  font-size: 26px;
  color: #0066cc;
  margin-bottom: 10px;
}

.features-div {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-top: -40px;
}

.features ul {
  list-style-type: none;
  text-align: left;
  max-width: 400px;
  padding: 20px;
  border-radius: 20px;
  background-color: rgba(96, 48, 150, 0.35);
  box-shadow: 0 0 35px 0 rgba(0, 0, 0, 0.3);
  padding: 28px ;
  margin-top: 30px;


}

.features-list li {
  font-size: 18px;
  margin-bottom: 10px;
  line-height: 1.6;
}

.join h2 {
  font-size: 26px;
  color: #0066cc;
  margin-bottom: 20px;
}

.join-text {
  font-size: 18px;
  color: #333;
  margin-bottom: 30px;
}

.login-form {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 20px;
  background-color: rgba(96, 48, 150, 0.35);
  box-shadow: 0 0 35px 0 rgba(0, 0, 0, 0.3);
}

.form-group {
  margin-bottom: 10px;
}

label {
  display: block;
  font-weight: 700;
  font-size: 18px;
  text-align: left;
}

input[type="email"],
input[type="password"] {
  width: 100%;
  padding: 12px 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 16px;
  margin-top: 5px;

}

.login-buttons {
  display: flex;
  justify-content: space-between;
}

.login-button {
  padding: 10px 30px;
  border: none;
  border-radius: 4px;
  color: #fff;
  background-color: #0066cc;
  cursor: pointer;
  font-size: 16px;
  width: 100%;
  margin: 0 4px;


}

.login-button.google {
  background-color: #dd4b39;
}

.forgot-password,
.signup {
  font-size: 14px;
  color: #9b9797;
  margin-bottom: 10px;
}

.forgot-password a,
.signup a {
  color: #378de4;
  text-decoration: none;
}

.support {
  font-size: 17px;
  color: #ddd6d6;
  margin-top: 20px;
}

.support a {
  color: #0f6fce;
  text-decoration: none;
}

.support a:hover {
  text-decoration: underline;
}

.error-message {
  font-weight: 700;
  font-size: 18px;
  color: rgb(255, 38, 0);
  position: relative;
  top: -60px;
  left: 170px;
}