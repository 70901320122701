.ag-header-cell-label {
  justify-content: center;
}

.log1 {
  margin-top: -7px;
  padding: 0;
}

.title4 {
  letter-spacing: 5px;
  margin: 0;
  padding: 0;
  color: rgba(171, 236, 236, 0.75);
}

.toUp1 {
  margin-top: -70px;
}

.total {
  font-size: 15.5px !important;
  margin: 6px 0px 3px 0px !important;
  padding: 0px 50px !important;
}

.createDeleteB {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 15px 10px 20px 10px;
}

/* .deleteB {
} */

.title1 {
  position: relative;
  top: 50px;
  /* margin-left: 440px; */
  color: rgba(171, 236, 236, 0.75);
  text-align: center;
}
