.DoughnutDimension7 {
  position: relative;
  left: 630px;
  top: 30px;
}

.LineDimension7 {
  position: relative;
  bottom: 90px;
  left: 20px;
}
