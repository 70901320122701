.ddd {
  margin-top: -24px;
}
.registerContainer {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-top: -45px; */
  flex-direction: column;
}
.errorMessageRegister {
  position: relative;
  /* margin-bottom: 150px; */
  top: -60px;
  left: 10px;
  font-weight: 500;
  font-size: 18px;
  color: rgb(255, 38, 0);
}

.registerForm {
  width: 36vw;
  /* height: 80vh; */
  height: 480px;
  padding: 5px;
  border-radius: 20px;
  background-color: rgba(96, 48, 150, 0.35);
  box-shadow: 0 0 35px 0 rgba(0, 0, 0, 0.3);
  margin-top: 7vh;
}
.labelInput {
  display: flex;
  flex-direction: column;
  margin: 15px 10px 0px 12px;
}
.formlabel {
  position: relative;
  margin-bottom: 4px;
  font-size: 18px;
}

.formInput {
  padding: 8px;
  background-color: #334146;
  color: #fff;
  border: none;
  border-radius: 8px;
  width: 29.5vw;
  font-size: 14px;
  font-weight: 500;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  margin-bottom: 2px;
  background-color: rgb(69, 69, 88);
}

.formButton {
  padding: 10px;
  border-radius: 8px;
  border: none;
  text-decoration: none;
  background-color: #445a63;
  color: #fff;
  cursor: pointer;
  margin: 12px 0px;
  font-size: 18px;
  width: 30.7vw;
  margin-left: 12px;
  margin-top: 5vh;
}
.formButton:active {
  opacity: 0.7;
}

.question {
  margin-right: 2vw;
  margin-top: 20px;
  text-align: center;
}

.linkto {
  color: rgba(168, 248, 248, 0.842);
}

/* .reset {
  height: 50vh;
} */

.errorMessage {
  /* margin-top: 35px; */
  /* margin-right: 80px; */
  color: rgb(196, 170, 55);
}


.login-button {
  padding: 10px 30px;
  border: none;
  border-radius: 4px;
  color: #fff;
  background-color: #0066cc;
  cursor: pointer;
  font-size: 16px;
  width: 530px;
  margin-top: 16px


}

.login-button.google {
  background-color: #dd4b39;
}