/* .MuiToolbar-root {
  margin: -6px;
} */


.Winput {
  background-color: rgb(75, 126, 202);

  margin-left: 50px;
  border: none;
  outline: none;
  font-size: 14px;
  color: rgb(218, 231, 243);
}
