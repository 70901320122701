.itnCover {
  position: absolute;

  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  top: 0;
  left: 0;

  /* background-image: url("../../images/PPP.jpeg");
  background-repeat: no-repeat;
  background-size: 105%;
  background-position-y: 0px;
  z-index: auto; */

  background: rgb(2, 0, 36);
  background: linear-gradient(
    90deg,
    rgb(5, 1, 78) 0%,
    rgba(9, 9, 121, 1) 35%,
    rgb(99, 6, 153) 100%
  );
}

.itnCenter {
  position: absolute;
  /* color: rgb(255, 7, 7); */
  border: 10px solid rgb(10, 161, 56, 0.4);
  border-style: double;
  /* border-color: rgb(4, 78, 26, 0.4); */
  height: 150px;
  width: 150px;
  border-radius: 100%;
  font-family: "Times New Roman";
  font-size: 18px;
  top: 60px;
  bottom: 0;
  left: 0;
  right: 10px;
  margin: auto;
  z-index: 1;
}

.approuval {
  margin-top: 35px;
  line-height: 0px;
  transition: all 5s ease-out;
  font-size: 25px;
  color: rgb(10, 161, 56);
  cursor: pointer;
}

.approuval:hover ~ .delete,
.approuval:hover ~ .update,
.approuval:hover ~ .print,
.approuval:hover ~ .seeItn,
.approuval:hover ~ .Uploadimages,
.approuval:hover ~ .uploadItn {
  font-size: 14px;
}

.update {
  position: absolute;
  top: 70px;
  right: 165px;
  width: 150px;
  cursor: pointer;
}

.delete {
  position: absolute;
  bottom: 50px;
  left: 170px;
  width: 150px;
  cursor: pointer;
}

.print {
  position: absolute;
  top: 57px;
  right: 160px;
  width: 200px;
  cursor: pointer;
}

.seeItn {
  position: absolute;
  top: 23px;
  left: 120px;
  width: 200px;
  padding: 0 1px;
  cursor: pointer;
}

.uploadItn {
  position: absolute;
  bottom: 188px;
  left: 55px;
  width: 250px;
  padding: 0 1px;
  cursor: pointer;
}

.Uploadimages {
  position: absolute;
  bottom: 188px;
  right: 100px;
  width: 176px;
  padding: 0 1px;
  cursor: pointer;
}

.stamp {
  height: fit-content;
  transform: rotate(-10deg);
  color: #555;
  font-size: 3rem;
  font-weight: 700;
  border: 0.25rem solid #555;
  display: inline-block;
  padding: 0.25rem 1rem;
  text-transform: uppercase;
  border-radius: 1rem;
  font-family: "Courier";
  -webkit-mask-image: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/8399/grunge.png");
  -webkit-mask-size: 944px 604px;
  mix-blend-mode: multiply;
}

.is-approved {
  color: #0a9928;
  border: 0.5rem solid #0a9928;
  -webkit-mask-position: 13rem 6rem;
  transform: rotate(-12deg);
  border-radius: 0;
  z-index: 99;
  background-color: rgb(255, 255, 255);
}

.is-approved-w {
  color: #0a7099;
  border: 0.5rem double #0a7099;
  -webkit-mask-position: 13rem 6rem;
  transform: rotate(-12deg);
  z-index: 99;
  border-radius: 0;
  font-size: 40px;
  background-color: rgb(255, 255, 255);
}

.is-nope {
  color: #d23;
  border: 0.5rem double #d23;
  transform: rotate(8deg);
  -webkit-mask-position: 2rem 3rem;
  z-index: 99;
  font-size: 2.2rem;
  background-color: rgb(255, 255, 255);
}

.inf {
  z-index: 99;
  width: 260px;
  background-color: rgb(255, 255, 255);
}

.title1 {
  letter-spacing: 4px;
}
.title3 {
  letter-spacing: 4px;
}
.title2 {
  letter-spacing: 2px;
}
/* .modalP {
  position: relative;
  top: 22px;
  right: 55px;
} */

.image2 {
  position: absolute;
  top: -120px;
  left: 275px;
  transition: transform 1s;
  border-radius: 6px;
  border: 2px solid rgb(207, 207, 226);
}

.image1 {
  position: absolute;
  top: -120px;
  right: 318px;
  transition: transform 1s;
  border-radius: 6px;
  border: 2px solid rgb(207, 207, 226);
}

.image1:hover {
  transform: scale(1.4, 1.3);
  transform-origin: left center;
}

.image2:hover {
  transform: scale(1.3, 1.3);
  transform-origin: right center;
}

.logo1 {
  position: absolute;
  top: 58px;
  left: 160px;
  height: 80px;
  border: none !important;
  border-radius: 12%;
}
.logo2 {
  position: absolute;
  top: 58px;
  right: 160px;
  height: 80px;
  border: none !important;
  border-radius: 12%;
}
