

.dev {
  position: fixed;
  bottom: -12px;
  right: 0px;
  z-index: 99;
  background-color: rgba(0, 255, 179, 0.9);
  color: rgb(24, 24, 26);
  font-family: "Times New Roman";
  font-size: 17px;
  border-radius: 20px;
  padding: 3px;
  font-weight: 700;
}

/* .all {
  position: relative;
} */
/* .smallScreen {
  color: aliceblue;
  font-family: Calibri, sans-serif;
  font-weight: 700;
  font-size: larger;
  opacity: 0;
} */
/* @media only screen and (max-width: 1300px) {
  .App {
    display: none;
  }
  .smallScreen {
    margin-top: 200px;
    text-align: center;
    color: aliceblue;
    font-family: Calibri, sans-serif;
    font-weight: 700;
    font-size: larger;
    opacity: 1;
  }
} */
