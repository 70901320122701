body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* height: 100%; */
  /* width: 100%; */
  /* display: flex; */
  /* justify-content: center; */
  align-items: center;
  
  background: linear-gradient(
    90deg,
    rgb(5, 1, 78) 0%,
    rgba(9, 9, 121, 1) 35%,
    rgb(99, 6, 153) 100%
  );
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
