.locationName {
  position: fixed;
  top: 60px;
  left: 3vw;
  margin: 10px -25px;
  text-decoration: underline rgb(187, 224, 224);
  font-size: 20px;
  color: rgb(242, 250, 250);
}

.restOfPage {
  position: absolute;
}

.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2;
}
/* 
.createItn {
  position: fixed;
  top: 330px;
  left: 15px;
  margin: 27px 0px;
  font-size: 20px;
  padding: 15px;
  border-radius: 20px;
  border-color: rgb(206, 204, 204);
  border-style: solid;
  color: white;
  background-color: rgb(255, 71, 71);
  cursor: pointer;
} */
/* .createItn:active {
    background-color: rgb(247, 112, 112);
    transform: translate(1px, 1px);
  } */

.getItp1 {
  position: fixed;
  top: 110px !important;
  float: left;
  padding: 10px 63px !important;
  border-radius: 0 !important;
  box-shadow: 10px 5px 5px rgb(160, 153, 153) !important;
}
