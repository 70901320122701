.fullPage {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  /* background-color: #0fa; */
}

.masterPlan {



  /* background-color: red; */
  width: 100%;
  height: 100%;  
  /* width: 85%;
  height: 85%; */
  position: relative;
  box-sizing: border-box;



}

.secondaryClarifierP24 {
  cursor: pointer;
  width: 9.4%;
  height: 17.5%;
  position: absolute;
  bottom: 12.8%;
  left: 19%;
  border-radius: 100%;
  background-color: rgb(152, 0, 203);
  opacity: .3;

}


.secondaryClarifierP24:hover {
  box-shadow: 0 0 20px rgb(152, 0, 203), 0 0 20px rgb(152, 0, 203), 0 0 70px rgb(152, 0, 203);
  opacity: 0.3;
}

.name-secondaryClarifierP24 {
  position: absolute;
  bottom: 11.8%;
  left: 17.6%;
  opacity: 0;
  transition: 1.2s opacity;
  color: crimson;
  font-size: 1rem;
  background-color: rgba(225, 243, 243, 0.85);
  border-radius: 15px;
   
}

.secondaryClarifierP24:hover + .name-secondaryClarifierP24 {
  opacity: 1;
}

.secondaryClarifierP25 {
  cursor: pointer;
  width: 9.4%;
  height: 17.5%;
  position: absolute;
  bottom: 12.8%;
  left: 33.4%;
  border-radius: 100%;
  background-color: rgb(152, 0, 203);

  opacity: .3;
}
.secondaryClarifierP25:hover {
  box-shadow: 0 0 20px rgb(152, 0, 203), 0 0 20px rgb(152, 0, 203), 0 0 70px rgb(152, 0, 203);
  opacity: 0.3;
}

.name-secondaryClarifierP25 {
  position: absolute;
  bottom: 11.8%;
  left: 32.8%;
  opacity: 0;
  transition: 1.2s opacity;
  color: crimson;
  font-size: 1rem;
  background-color: rgba(225, 243, 243, 0.85);
  border-radius: 15px;
 
}

.secondaryClarifierP25:hover + .name-secondaryClarifierP25 {
  opacity: 1;
}

.secondaryClarifierP32 {
  cursor: pointer;
  width: 9.4%;
  height: 17.5%;
  position: absolute;
  bottom: 36.5%;
  left: 33.4%;
  border-radius: 100%;
    background-color: rgb(152, 0, 203);

  opacity: .3;

}
.secondaryClarifierP32:hover {
  box-shadow: 0 0 20px rgb(152, 0, 203), 0 0 20px rgb(152, 0, 203), 0 0 70px rgb(152, 0, 203);
  opacity: 0.3;
}

.name-secondaryClarifierP32 {
  position: absolute;
  bottom: 34.8%;
  left: 32.8%;
  opacity: 0;
  transition: 1.2s opacity;
  color: crimson;
  font-size: 1rem;
  background-color: rgba(225, 243, 243, 0.85);
  border-radius: 15px;
 
}

.secondaryClarifierP32:hover + .name-secondaryClarifierP32 {
  opacity: 1;
}

.PrimaryClarifierP7 {
  cursor: pointer;
  width: 7%;
  height: 12%;
  position: absolute;
  bottom: 38.5%;
  right: 17.5%;
  border-radius: 100%;
    background-color: rgb(152, 0, 203);

  opacity: .3;
}
.PrimaryClarifierP7:hover {
  box-shadow: 0 0 20px rgb(152, 0, 203), 0 0 20px rgb(152, 0, 203), 0 0 70px rgb(152, 0, 203);
  opacity: 0.3;
}

.name-PrimaryClarifierP7 {
  position: absolute;
  bottom: 39.5%;
  right: 16.5%;
  opacity: 0;
  transition: 1.2s opacity;
  color: crimson;
  font-size: 1rem;
  background-color: rgba(225, 243, 243, 0.85);
  border-radius: 15px;
 
}

.PrimaryClarifierP7:hover + .name-PrimaryClarifierP7 {
  opacity: 1;
}

.PrimaryClarifierP8 {
  cursor: pointer;
  width: 7%;
  height: 12%;
  position: absolute;
  bottom: 25.5%;
  right: 17.5%;
  border-radius: 100%;
  background-color: rgb(152, 0, 203);

  opacity: .3;
}
.PrimaryClarifierP8:hover {
  box-shadow: 0 0 20px rgb(152, 0, 203), 0 0 20px rgb(152, 0, 203), 0 0 70px rgb(152, 0, 203);
  opacity: 0.3;
}

.name-PrimaryClarifierP8 {
  position: absolute;
  bottom: 25.5%;
  right: 16.5%;
  opacity: 0;
  transition: 1.2s opacity;
  color: crimson;
  font-size: 1rem;
  background-color: rgba(225, 243, 243, 0.85);
  border-radius: 15px;
 
}

.PrimaryClarifierP8:hover + .name-PrimaryClarifierP8 {
  opacity: 1;
}

.PrimaryClarifierP9 {
  cursor: pointer;
  width: 7%;
  height: 12%;
  position: absolute;
  bottom: 12.5%;
  right: 17.5%;
  border-radius: 100%;
  background-color: rgb(152, 0, 203);

  opacity: .3;
}
.PrimaryClarifierP9:hover {
  box-shadow: 0 0 20px rgb(152, 0, 203), 0 0 20px rgb(152, 0, 203), 0 0 70px rgb(152, 0, 203);
  opacity: 0.3;
}

.name-PrimaryClarifierP9 {
  position: absolute;
  bottom: 11.5%;
  right: 16.5%;
  opacity: 0;
  transition: 1.2s opacity;
  color: crimson;
  font-size: 1rem;
  background-color: rgba(225, 243, 243, 0.85);
  border-radius: 15px;
 
}

.PrimaryClarifierP9:hover + .name-PrimaryClarifierP9 {
  opacity: 1;
}

.aerationTank {
  cursor: pointer;
  width: 19.5%;
  height: 34%;
  position: absolute;
  bottom: 13%;
  right: 33%;
  border-radius: 5%;
  background-color: #00d9ff;
  opacity: .3;
}
.aerationTank:hover {
  box-shadow: 0 0 20px rgb(152, 0, 203), 0 0 20px rgb(152, 0, 203), 0 0 70px rgb(152, 0, 203);
  opacity: 0.3;
}

.name-aerationTank {
  position: absolute;
  bottom: 13%;
  right: 33%;
  opacity: 0;
  transition: 1.2s opacity;
  color: crimson;
  font-size: 1rem;
  background-color: rgba(225, 243, 243, 0.85);
  border-radius: 15px;
 
}

.aerationTank:hover + .name-aerationTank {
  opacity: 1;
}

.mainBuilding {
  cursor: pointer;
  width: 8%;
  height: 18%;
  position: absolute;
  bottom: 18.4%;
  left: 8.5%;
  border-radius: 5%;
    background-color: rgb(152, 0, 203);

  opacity: .3;
}
.mainBuilding:hover {
  box-shadow: 0 0 20px rgb(152, 0, 203), 0 0 20px rgb(152, 0, 203), 0 0 70px rgb(152, 0, 203);
  opacity: 0.3;
}

.name-mainBuilding {
  position: absolute;
  bottom: 18.2%;
  left: 6.5%;
  opacity: 0;
  transition: 1.2s opacity;
  color: crimson;
  font-size: 1rem;
  background-color: rgba(225, 243, 243, 0.85);
  border-radius: 15px;
 
}

.mainBuilding:hover + .name-mainBuilding {
  opacity: 1;
}

.workShop {
  cursor: pointer;
  width: 3%;
  height: 6%;
  position: absolute;
  bottom: 36%;
  left: 8.5%;
  border-radius: 5%;
  background-color: #00d9ff;
  opacity: .5;
}
.workShop:hover {
  box-shadow: 0 0 20px rgb(152, 0, 203), 0 0 20px rgb(152, 0, 203), 0 0 70px rgb(152, 0, 203);
  opacity: 0.3;
}

.name-workShop {
  position: absolute;
  bottom: 36%;
  left: 9.5%;
  opacity: 0;
  transition: 1.2s opacity;
  color: crimson;
  font-size: 1rem;
  background-color: rgba(225, 243, 243, 0.85);
  border-radius: 15px;
 
}

.workShop:hover + .name-workShop {
  opacity: 1;
}

.chlorinationTank {
  cursor: pointer;
  width: 7%;
  height: 15%;
  position: absolute;
  bottom: 42%;
  left: 8.5%;
  border-radius: 5%;
    background-color: rgb(152, 0, 203);

  opacity: .3;
}
.chlorinationTank:hover {
  box-shadow: 0 0 20px rgb(152, 0, 203), 0 0 20px rgb(152, 0, 203), 0 0 70px rgb(152, 0, 203);
  opacity: 0.3;
}

.name-chlorinationTank {
  position: absolute;
  bottom: 57%;
  left: 6.5%;
  opacity: 0;
  transition: 1.2s opacity;
  color: crimson;
  font-size: 1rem;
  background-color: rgba(225, 243, 243, 0.85);
  border-radius: 15px;
 
}

.chlorinationTank:hover + .name-chlorinationTank {
  opacity: 1;
}

.pumpingStation2 {
  cursor: pointer;
  width: 3.5%;
  height: 4.5%;
  position: absolute;
  bottom: 33.5%;
  left: 20%;
  border-radius: 5%;
  background-color: rgb(152, 0, 203);
  opacity: .3;
}
.pumpingStation2:hover {
  box-shadow: 0 0 20px rgb(152, 0, 203), 0 0 20px rgb(152, 0, 203), 0 0 70px rgb(152, 0, 203);
  opacity: 0.3;
}

.name-pumpingStation2 {
  position: absolute;
  bottom: 29%;
  left: 19%;
  opacity: 0;
  transition: 1.2s opacity;
  color: crimson;
  font-size: 1rem;
  background-color: rgba(225, 243, 243, 0.85);
  border-radius: 15px;
 
}

.pumpingStation2:hover + .name-pumpingStation2 {
  opacity: 1;
}

.pumpingStation1 {
  cursor: pointer;
  width: 4%;
  height: 5%;
  position: absolute;
  bottom: 28%;
  right: 28.6%;
  border-radius: 5%;
  background-color: rgb(152, 0, 203);
  opacity: .3;
}
.pumpingStation1:hover {
  box-shadow: 0 0 20px rgb(152, 0, 203), 0 0 20px rgb(152, 0, 203), 0 0 70px rgb(152, 0, 203);
  opacity: 0.3;
}

.name-pumpingStation1 {
  position: absolute;
  bottom: 23.7%;
  right: 23.2%;
  opacity: 0;
  transition: 1.2s opacity;
  color: crimson;
  font-size: 1rem;
  background-color: rgba(225, 243, 243, 0.85);
  border-radius: 15px;
 
}

.pumpingStation1:hover + .name-pumpingStation1 {
  opacity: 1;
}

.sandFilter {
  cursor: pointer;
  width: 8%;
  height: 10%;
  position: absolute;
  bottom: 40%;
  left: 20%;
  border-radius: 5%;
  background-color: #00d9ff;
  opacity: .3;
}
.sandFilter:hover {
  box-shadow: 0 0 20px rgb(152, 0, 203), 0 0 20px rgb(152, 0, 203), 0 0 70px rgb(152, 0, 203);
  opacity: 0.3;
}

.name-sandFilter {
  position: absolute;
  bottom: 50.2%;
  left: 19%;
  opacity: 0;
  transition: 1.2s opacity;
  color: crimson;
  font-size: 1rem;
  background-color: rgba(225, 243, 243, 0.85);
  border-radius: 15px;
 
}

.sandFilter:hover + .name-sandFilter {
  opacity: 1;
}

.electricalRoom1 {
  cursor: pointer;
  width: 6%;
  height: 5%;
  position: absolute;
  bottom: 50%;
  right: 29.5%;
  border-radius: 5%;
  background-color: #00d9ff;
  opacity: .3;
}
.electricalRoom1:hover {
  box-shadow: 0 0 20px rgb(152, 0, 203), 0 0 20px rgb(152, 0, 203), 0 0 70px rgb(152, 0, 203);
  opacity: 0.3;
}

.name-electricalRoom1 {
  position: absolute;
  bottom: 55%;
  right: 27%;
  opacity: 0;
  transition: 1.2s opacity;
  color: crimson;
  font-size: 1rem;
  background-color: rgba(225, 243, 243, 0.85);
  border-radius: 15px;
 
}

.electricalRoom1:hover + .name-electricalRoom1 {
  opacity: 1;
}

.electricalRoom2 {
  cursor: pointer;
  width: 3%;
  height: 10%;
  position: absolute;
  bottom: 45.2%;
  left: 16.7%;
  border-radius: 5%;
  background-color: rgb(152, 0, 203);
  opacity: .3;
}
.electricalRoom2:hover {
  box-shadow: 0 0 20px rgb(152, 0, 203), 0 0 20px rgb(152, 0, 203), 0 0 70px rgb(152, 0, 203);
  opacity: 0.3;
}

.name-electricalRoom2 {
  position: absolute;
  bottom: 55.2%;
  left: 15.7%;
  opacity: 0;
  transition: 1.2s opacity;
  color: crimson;
  font-size: 1rem;
  background-color: rgba(225, 243, 243, 0.85);
  border-radius: 15px;
 
}

.electricalRoom2:hover + .name-electricalRoom2 {
  opacity: 1;
}

.blowersRoom {
  cursor: pointer;
  width: 5%;
  height: 7%;
  position: absolute;
  bottom: 48.6%;
  right: 35.8%;
  border-radius: 5%;
    background-color: rgb(152, 0, 203);

  opacity: .3;
}
.blowersRoom:hover {
  box-shadow: 0 0 10px #0fa, 0 0 20px #0fa, 0 0 30px #0fa;
  opacity: 0.3;
}

.name-blowersRoom {
  position: absolute;
  bottom: 55.6%;
  right: 35.1%;
  opacity: 0;
  transition: 1.2s opacity;
  color: crimson;
  font-size: 1rem;
  background-color: rgba(225, 243, 243, 0.85);
  border-radius: 15px;
 
}

.blowersRoom:hover + .name-blowersRoom {
  opacity: 1;
}

.mainStation {
  cursor: pointer;
  width: 5.5%;
  height: 9.5%;
  position: absolute;
  bottom: 62.6%;
  left: 8.5%;
  border-radius: 5%;
  background-color: #00d9ff;
  opacity: .3;
}
.mainStation:hover {
  box-shadow: 0 0 10px #0fa, 0 0 20px #0fa, 0 0 30px #0fa;
  opacity: 0.3;
}

.name-mainStation {
  position: absolute;
  bottom: 72.6%;
  left: 6.5%;
  opacity: 0;
  transition: 1.2s opacity;
  color: crimson;
  font-size: 1rem;
  background-color: rgba(225, 243, 243, 0.85);
  border-radius: 15px;
 
}

.mainStation:hover + .name-mainStation {
  opacity: 1;
}

.sludgeTreatmentBuilding {
  cursor: pointer;
  width: 5.5%;
  height: 9.5%;
  position: absolute;
  bottom: 62.6%;
  left: 39%;
  border-radius: 5%;
  background-color: #00d9ff;
  opacity: .3;
}
.sludgeTreatmentBuilding:hover {
  box-shadow: 0 0 10px #0fa, 0 0 20px #0fa, 0 0 30px #0fa;
  opacity: 0.3;
}

.name-sludgeTreatmentBuilding {
  position: absolute;
  bottom: 72.6%;
  left: 38.5%;
  opacity: 0;
  transition: 1.2s opacity;
  color: crimson;
  font-size: 1rem;
  background-color: rgba(225, 243, 243, 0.85);
  border-radius: 15px;
 
}

.sludgeTreatmentBuilding:hover + .name-sludgeTreatmentBuilding {
  opacity: 1;
}

.digester {
  cursor: pointer;
  position: absolute;
  top: 106px;
  left: 370px;
  height: 138px;
  width: 84px;
  opacity: 1;
  box-shadow: 0 0 14px #049dff, 0 0 5px #049dff, 0 0 15px #049dff;
  border-radius: 10%;
}
.digester:hover {
  box-shadow: 0 0 10px #0fa, 0 0 20px #0fa, 0 0 30px #0fa;
  opacity: 0.3;
}

.name-digester {
  position: absolute;
  left: 270px;
  top: 100px;
  opacity: 0;
  transition: 1.2s opacity;
  font-size: 20px;
  border-radius: 15px;
  background-color: rgba(225, 243, 243, 0.85);
 
}

.digester:hover + .name-digester {
  opacity: 1;
}

.preliminaryTreatment {
  cursor: pointer;
  width: 3.3%;
  height: 9.3%;
  position: absolute;
  bottom: 12.3%;
  right: 27.5%;
  border-radius: 5%;
  background-color: rgb(152, 0, 203);
  opacity: .3;
}
.preliminaryTreatment:hover {
  box-shadow: 0 0 10px #0fa, 0 0 20px #0fa, 0 0 30px #0fa;
  opacity: 0.3;
}

.name-preliminaryTreatment {
  position: absolute;
  bottom: 8.3%;
  right: 26.5%; 
  opacity: 0;
  transition: 1.2s opacity;
  color: crimson;
  font-size: 1rem;
  background-color: rgba(225, 243, 243, 0.85);
  border-radius: 15px;
 
}

.preliminaryTreatment:hover + .name-preliminaryTreatment {
  opacity: 1;
}

/* .generator {
  cursor: pointer;
  position: absolute;
  top: 220px;
  left: 215px;
  height: 15px;
  width: 10px;
  opacity: 0;
  box-shadow: 0 0 14px #049dff, 0 0 5px #049dff;

  border-radius: 10%;
}
.generator:hover {
  box-shadow: 0 0 10px #0fa, 0 0 20px #0fa, 0 0 30px #0fa;
  opacity: 0.7;
}

.name-generator {
  position: absolute;
  left: 220px;
  bottom: 420px;
  opacity: 0;
  transition: 1.2s opacity;
  font-size: 20px;
  border-radius: 15px;
  background-color: rgba(225, 243, 243, 0.85);
 
}

.generator:hover + .name-generator {
  opacity: 1;
} */

.name-waterPipesNetwork
{
  position: absolute;
  right: 450px;
  bottom: 50px;
  opacity: 0;
  transition: 1.2s opacity;
  font-size: 20px;
  border-radius: 15px;
  background-color: rgba(225, 243, 243, 0.85);
 
}

.name-digester1
{
  position: absolute;
  right: 450px;
  bottom: 50px;
  opacity: 0;
  transition: 1.2s opacity;
  font-size: 20px;
  border-radius: 15px;
  background-color: rgba(225, 243, 243, 0.85);
 
}
.name-digester2
{
  position: absolute;
  right: 450px;
  bottom: 50px;
  opacity: 0;
  transition: 1.2s opacity;
  font-size: 20px;
  border-radius: 15px;
  background-color: rgba(225, 243, 243, 0.85);
 
}
.name-other
{
  position: absolute;
  right: 450px;
  bottom: 50px;
  opacity: 0;
  transition: 1.2s opacity;
  font-size: 20px;
  border-radius: 15px;
  background-color: rgba(225, 243, 243, 0.85);
 
}

.closingWall {
  position: absolute;
  top: 60px;
  text-decoration: none;
  font-size: 18px;
  font-weight: 600;
}
.wallText {
  position: relative;
  left: 330px;
  top: 15px;
}
.arrow {
  width: 120px;
}

/* .line {
  margin-top: 14px;
  width: 90px;
  background: rgb(106, 106, 204);
  height: 10px;
  margin-left: 360px;
}

.point {
  width: 0;
  height: 0;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-left: 30px solid rgb(106, 106, 204);
  margin-left: 450px;
  margin-top: -25px;
} */
